export default class RequestBuilder {
  static LINE_BREAK = " \\\n" // Constant for line breaks

  constructor(url) {
    this.url = new URL(url) // Use the URL object for easier manipulation of query params
    this.method = "GET" // default method
    this.headers = new Headers()
    this.cookies = []
    this.body = null // for NON GET requests
  }

  // Set the HTTP method
  setMethod(method) {
    this.method = method
    return this
  }

  // Add a header
  setHeader(name, value) {
    this.headers.set(name, value)
    return this
  }

  // Add a cookie
  addCookie(name, value) {
    this.cookies.push(`${name}=${value}`)
    return this
  }

  // Add a query parameter
  addQueryParam(name, value) {
    this.url.searchParams.append(name, value)
    return this
  }

  // Set the request body
  setBody(body) {
    this.body = body
    return this
  }

  // Update the URL before sending the request
  updateUrl(newUrl) {
    this.url = new URL(newUrl)
    return this
  }

  // Build the request object
  build() {
    // If there are cookies, append them to the headers
    if (this.cookies.length > 0) {
      this.headers.append("Cookie", this.cookies.join(" "))
    }

    // Create the request options object
    const requestOptions = {
      method: this.method,
      headers: this.headers,
      credentials: "include", // To include cookies automatically if necessary
    }

    // Only include the body for methods other than GET
    if (this.method.toUpperCase() !== "GET" && this.body) {
      if (this.headers.get("Content-Type") === "application/json") {
        requestOptions.body = JSON.stringify(this.body)
      } else {
        requestOptions.body = new URLSearchParams(this.body)
      }
    }

    // Create the request object
    return new Request(this.url.toString(), requestOptions)
  }
}
