import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["requestForm", "urlForm", "shareField", "server", "currentServer"]

  // following https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript/53221307#53221307,
  // this base64 object will be us ton encode and decode object to string with base64 format,
  // supporting characters outside of Ascii range (user may fill Explorer form with emoji, 💩 happens).
  base64 = {
    decode: s => Uint8Array.from(atob(s), c => c.charCodeAt(0)),
    encode: b => btoa(String.fromCharCode(...new Uint8Array(b))),
    decodeToString: s => new TextDecoder().decode(this.base64.decode(s)),
    encodeString: s => this.base64.encode(new TextEncoder().encode(s)),
  }

  connect() {
    const digestParam = new URL(document.location).searchParams.get("digest")

    if (digestParam !== null) {
      this.#fillFormFromDigest(digestParam)
    }
  }

  // When modal "share request" is open by current user, URL to share is generated
  // here with a parameter 'digest'. This URL is included in copiable field.
  fillShareUrl() {
    this.shareFieldTarget.innerText = this.#generateShareUrl()
  }

  #generateShareUrl() {
    const shareUrl = new URL(window.location.href)
    shareUrl.search = ""
    shareUrl.searchParams.set("digest", this.#generateDigest())
    shareUrl.searchParams.set("server_id", this.#getCurrentServerId())

    return shareUrl.href
  }

  #getCurrentServerId() {
    const currentServer = this.serverTargets.find((server) => {
      return server.dataset.url === this.currentServerTarget.innerText.trim()
    })

    return currentServer.dataset.id
  }

  #generateDigest() {
    // create JS object, with top-level key values,
    // where all fields values will be stored.
    // this digest can also share additional informations (version_id, server, timestamp...)
    const urlFormData = new FormData(this.urlFormTarget)
    const requestFormData = new FormData(this.requestFormTarget)
    const digestObject = {
      values: {
        urlForm: this.#formDataToObject(urlFormData),
        requestForm: this.#formDataToObject(requestFormData)
      }
    }

    const stringToDigest = JSON.stringify(digestObject)
    const digest = this.#encodeString(stringToDigest) // safe Ascii support

    return digest
  }

  #formDataToObject(formData) {
    const formDataObject = {}

    for (let [key, value] of formData.entries()) {
      if (value === null || value === undefined) continue

      const trimmedValue = String(value).trim()

      if (trimmedValue !== "") {
        formDataObject[key] = trimmedValue
      }
    }

    return formDataObject
  }

  // When user access to explorer page, if a search parameter 'digest' is detected,
  // this function will decode the digest parameter to a JS Object,
  // and fill every form fields with these decoded values.
  #fillFormFromDigest(digest) {
    const decodedString = this.#decodeString(digest)
    const decodedObject = JSON.parse(decodedString)

    // fill query, path and headers parameters in urlForm
    this.#populateForm(decodedObject.values.urlForm, this.urlFormTarget)

    // fill body parameters in requestForm
    this.#populateForm(decodedObject.values.requestForm, this.requestFormTarget)
  }

  #populateForm(values, form) {
    for (const [key, value] of Object.entries(values)) {
      const formInput = form.elements[key]
      this.#fillFormInputWithValue(formInput, value)
    }
  }

  #fillFormInputWithValue(input, value) {
    if (input !== undefined) {
      if (["radio", "checkbox"].includes(input.type)) {
        input.checked = !!value
      } else {
        input.value = value
      }
    }
  }

  #encodeString(serializedJSON) {
    return this.base64.encodeString(serializedJSON)
  }

  #decodeString(digestParam) {
    return this.base64.decodeToString(digestParam)
  }
}
