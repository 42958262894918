import { Controller } from "@hotwired/stimulus"
import { serializeJSON } from "@syneto/serializejson"
import RequestBuilder from "../../global/javascript/request_builder"
import RequestToCurl from "../../global/javascript/request_to_curl"

export default class extends Controller {
  static targets = ["requestForm", "responseContent", "responseStatus", "trackerForm", "urlForm", "preview", "icon", "loader"]

  static values = { enctype: String, operationPath: String, baseUrl: String }

  // Response elements which needs to interact with the Syntax-Highlight controller
  static outlets = ["syntax-highlight"]

  connect() {
    this.#initializeRequestBuilder()
    this.updateRequest()
  }

  // For now we have a unique entrypoint to handle both successfully
  // (2xx) or errored (all other status) responses
  //
  // The event object has three elements which are defined by Rails UJS
  // https://github.com/rails/rails/blob/v7.1.3.4/actionview/app/assets/javascripts/rails-ujs.js#L91-L99
  onResponse(event) {
    const [response, statusText, xhr] = event.detail

    if (xhr.status) {
      const status = xhr.status
      let content = response

      try {
        content = JSON.parse(response)
      } catch {
        content = response.text()
      }

      this.#setResponseStatusAndContent(status, JSON.stringify(content, null, 2))
    } else {
      this.#unexpectedErrorHandler({ name: statusText, message: response })
    }
  }

  onSubmit(event) {
    event.preventDefault()

    if (!this.isLoading) {
      this.#submitFormData()

      this.#toggleLoadingState(true)
      this.#trackRequest()
    }
  }

  updateApiKey(event) {
    this.requestBuilder.setHeader("ApiKey", event.detail.apiKey)
    this.#generatePreview()
  }

  updateRequest() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      const serializedUrlForm = serializeJSON(this.urlFormTarget)
      let serializedRequestForm = serializeJSON(this.requestFormTarget, {
        skipFalsyValuesForTypes: ["string"]
      })
      const pathParameters = serializedUrlForm["path_parameters"]
      const queryParameters = serializedUrlForm["query_parameters"]
      this.requestBuilder.updateUrl(this.#computeUrl(pathParameters, queryParameters))

      if (serializedUrlForm["headers"]) {
        Object.entries(serializedUrlForm["headers"]).forEach((key, value) => this.requestBuilder.setHeader(key, value))
      }

      if (serializedRequestForm) {
        delete serializedRequestForm._method
      }
      this.requestBuilder.setBody(serializedRequestForm)
      this.#generatePreview()
    }, 500)
  }

  updateServer(server) {
    this.baseUrlValue = server
    this.updateRequest()
  }

  async #generatePreview() {
    const requestToCurl = new RequestToCurl(this.requestBuilder.build())
    const curlSnippet = await requestToCurl.convert()
    // Curly Braces are not URL safe so they are escape by the lib
    // We add them back for a nicer display experience
    const displayableCurlSnippet = curlSnippet.replaceAll("%7B", "{").replaceAll("%7D", "}")
    this.previewTarget.innerHTML = displayableCurlSnippet

    const outlet = this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.previewTarget))
    if (outlet) {
      outlet.highlight()
    }
  }

  #toggleLoadingState(toggle) {
    this.loaderTarget.setAttribute("aria-hidden", !toggle)
    this.loaderTarget.setAttribute("aria-busy", toggle)
    this.iconTarget.setAttribute("aria-hidden", toggle)
    this.isLoading = toggle
  }

  // Replace path parameters in the given URL and add query parameters
  // as an URL encoded query string.
  #computeUrl(pathParameters, queryParameters) {
    let rawPath = this.operationPathValue

    // Interpolate path parameter into rawPath
    if (pathParameters) {
      const filteredPathParameters = Object.entries(pathParameters).filter(([, value]) => value !== null && value !== undefined && value !== "")
      rawPath = filteredPathParameters.reduce((acc, [key, value]) => acc.replace(`{${key}}`, value), rawPath)
    }

    // Join base URL with operation path (working with trailing slash
    // on base URL or not)
    let targetUrl = new URL(`.${rawPath}`, `${this.baseUrlValue}/`)
    targetUrl.pathname = targetUrl.pathname.replace("//", "/")

    if (queryParameters) {
      targetUrl.search = new URLSearchParams(queryParameters)
    }

    return targetUrl.toString()
  }

  #submitFormData() {
    const self = this


    fetch(this.requestBuilder.build())
      .then((response) => {
        response.text().then((data) => {
          // We build an event object passed to 'onResponse' to have
          // the same signature as the one defined by Rails UJS
          const event = { detail: [data, response.statusText, response] }

          self.onResponse(event)
        })
      })
      .catch(this.#unexpectedErrorHandler.bind(this))
  }

  #unexpectedErrorHandler(error) {
    let title = error.name
    let message = error.message

    if (window.location.hostname != this.requestBuilder.url.hostname) {
      // It's not possible to detect a CORS error programmaticaly per
      // browsers implementation.
      //
      // It's also not possible to send a manual “preflight” CORS
      // request to check its status because of the forbidden request
      // header name “Access-Control-Request-Method” (cf
      // https://developer.mozilla.org/en-US/docs/Glossary/Forbidden_header_name)
      //
      // Thus, we assume that an error occuring when the current page
      // hostname is different from the target hostname is probably a CORS error.
      title = "CORS Error"
      message = "Unable to process the request. It might be related to a CORS error. Please contact the API provider."
    }

    // Set error details in UI
    this.#setResponseStatusAndContent(title, message)
  }

  #setResponseStatusAndContent(status, content) {
    this.#toggleLoadingState(false)
    this.responseStatusTarget.innerText = status
    this.responseStatusTarget.dataset.statusCode = status
    this.responseStatusTarget.removeAttribute("hidden")
    this.responseContentTarget.textContent = content
    this.syntaxHighlightOutlets.find(outlet => outlet.element.contains(this.responseContentTarget)).highlight()
  }

  // Read the request method either from the hidden "_method" field or
  // from the form directly. This hidden field is the Rails magic for
  // PUT, PATCH or DELETE requests (because HTML forms only support
  // GET or POST requests)
  #retrieveFormMethod(form) {
    const serializedFormData = serializeJSON(form, {
      skipFalsyValuesForTypes: ["string"]
    })
    const method = serializedFormData["_method"] || form.method
    delete serializedFormData["_method"]
    return method
  }

  #trackRequest() {
    this.trackerFormTarget.requestSubmit()
  }

  #initializeRequestBuilder() {
    this.requestBuilder = new RequestBuilder(this.#computeUrl(null, null))
    this.requestBuilder.setMethod(this.#retrieveFormMethod(this.requestFormTarget))
    this.requestBuilder.setHeader("Content-Type", this.enctypeValue)
    this.requestBuilder.setHeader("Accept", "application/json")
  }
}
