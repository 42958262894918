import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "button", "title"]

  addOne(event) {
    event.preventDefault()
    const property = this.templateTarget.content.cloneNode(true)
    if (this.hasButtonTarget) {
      this.element.insertBefore(property, this.buttonTarget)
    } else {
      this.element.append(property)
    }
    this.#updateIncrement()
  }

  removeOne(event) {
    event.currentTarget.closest(".property").remove()
    this.#updateIncrement()
    const sendRequestController = this.application.getControllerForElementAndIdentifier(document.querySelector("main"), "explorer-send-request")
    sendRequestController.updateRequest()
  }

  #updateIncrement() {
    this.titleTargets.forEach((title, index) => {
      title.textContent = `${title.textContent.split("[")[0].trim()}[${index}]`
    })
  }
}
