import { CurlGenerator } from "curl-generator"
export default class RequestToCurl {
  constructor(request) {
    this.request = request
  }

  // Convert Request object to cURL command
  async convert() {
    const params = await this.#parseRequest(this.request)
    return CurlGenerator(params)
  }

  #capitalizeHeaderKey(headerKey) {
    return headerKey
      .toLowerCase()
      .split("-")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-")
  }

  async #parseRequest(request) {
    const headers = {}

    // Copy headers from the Request object
    request.headers.forEach((value, key) => {
      headers[this.#capitalizeHeaderKey(key)] = value
    })

    // Get request body, if present
    let body = null
    if (request.body) {
      // TODO, body should be parsed based on enctype
      // only JSON for now
      try {
        body = await request.json()
      } catch (e) {
        body = request.body // fallback to raw body string
      }
    }

    return {
      url: request.url,
      method: request.method,
      headers: headers,
      body: body || ""
    }
  }
}
